import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {TranslateService} from '@ngx-translate/core';
import {
    AuthInput,
    AuthType,
    CreateUserGQL,
    LoginGQL,
    User
} from '../../../generated/graphql';
import {Token} from '../../model/user/token';

declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
    file: File = null;
    authInput: AuthInput = new AuthInput();


    constructor(public router: Router,
                public router2: ActivatedRoute,
                public login: LoginGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);

    }

    ngOnInit(): void {
        if (this.getEnv().isLoginMock) {
            $('#success').modal('show');
        }
        this.authInput.redirectUri = window.location.protocol + '//' + window.location.host + window.location.pathname;
    }

    actionLogin(): void {
        const arrValidateFields = [
            {value: this.authInput.re, text: 'RE <br />'},
            {value: this.authInput.password, text: 'Senha <br />'},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')} <br><br> ${stringError}`, 'warning');
            return;
        }

        this.login.mutate({
            code: this.authInput.code,
            redirectUri: this.authInput.redirectUri,
            re: this.authInput.re,
            password: this.authInput.password,
        }).subscribe(
            ({data}) => {
                const a = data.login as AuthType;
                this.saveLocalUser(a);
            }, (err) => super.onError(err)
        );

    }

    actionLoginMock(): void {
        const arrValidateFields = [
            {value: this.authInput.email, text: 'E-mail <br />'},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')} <br><br> ${stringError}`, 'warning');
            return;
        }

        this.login.mutate({email: this.authInput.email}).subscribe(
            ({data}) => {
                const a = data.login as AuthType;
                this.saveLocalUser(a);
            }, (err) => super.onError(err)
        );

    }

    saveLocalUser(data) {
        const token = data as Token;
        this.setToken(token.token);
        this.userService.updateUser(token.user);
        this.userService.getStatusAuthenticated();
        this.router.navigate(['/']).then();
    }

    onShowForgot() {
        this.authInput.re = '';
        this.showModal('modalForgot');
    }
}
