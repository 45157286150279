<div class="login">
    <div class="header">
        <svg-icon src="assets/svg/title.svg"></svg-icon>
        <div class="desc">
            <p>{{ 'euroestudo'|translate }}</p>
        </div>
    </div>
    <div class="content">
        <div class="form">
            <b>LOGIN</b>
            <ng-container *ngIf="false">
                <div class="input-material w-80">
                    <input id="fieldRE" [(ngModel)]="this.authInput.re" class="form-control" type="text" required/>
                    <label for="fieldRE">
                        <span>RE</span>
                    </label>
                </div>
                <div class="input-material w-80">
                    <input id="senha" [(ngModel)]="this.authInput.password" class="form-control" type="password"
                           required
                           (keyup.enter)="actionLogin()"/>
                    <label for="senha">
                        <span>Senha</span>
                    </label>
                </div>
                <button class="btn btn-warning  w-80 mt-3" tabindex="0" (click)="actionLogin()">
                    <span class="pl-2">Entrar</span>
                </button>
                <div class="d-flex align-items-baseline justify-content-center w-80">
                    <div class="divisor"></div>
                    <p class="mt-4 mx-3"> ou </p>
                    <div class="divisor"></div>
                </div>
            </ng-container>
            <a class="btn btn-primary btn-lg text-white w-80 mt-3" [routerLink]="'/authenticate'">
                <b class="text-white">Login Eurofarma</b>
            </a>
        </div>
    </div>
</div>

<div id="success" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body flex-column" style="min-height:initial;">
                <h1>Login Mock</h1>
                <div class="input-material w-100">
                    <input id="email" [(ngModel)]="this.authInput.email" class="form-control" type="text" required/>
                    <label for="email">E-mail</label>
                </div>
                <button class="btn btn-block btn-primary mt-4" (click)="actionLoginMock()" data-dismiss="modal">Entrar</button>
            </div>
        </div>
    </div>
</div>
